h1 {
    color: var(--blue-darkest, #001331);
    font-family: "Roboto Mono";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 43.2px */
}

body {
    font-family: 'Roboto Mono', monospace;
    background-color: #f6f9ff;
}

form {
    background-color: #fbfcfc;
}



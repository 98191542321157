/* Add this to your CSS file */
.my-masonry-grid {
  display: flex;
  margin-top: 16px;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Optional: Style the cards */
.my-masonry-grid_column > div { /* change div to match your card element */
  margin-bottom: 20px;
}

